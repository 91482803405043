<template>
	<edit-template class="role-edit" ref="edit" @confirm="save" @cancel="open" :cancelRemind="false" confirmText="保存" cancelText="取消">

		<!-- 大表单 -->
		<div class="form-body" style="padding-top: 70rem">
			<el-form ref="form" :model="form" label-width="150rem" size="medium" :rules="rules">
				<el-form-item label="校区" prop="school_id">
					<el-select :popper-append-to-body="false" v-model="form.school_id" placeholder="请选择" @change="changeSchool">
						<el-option v-for="(item, index) in this.schoolList" :label="item" :value="index" :key="index">
							{{ item }}
						</el-option>
					</el-select>
					<!--          <div v-else v-for="item in userInfo.school_arr">{{ item }}</div>-->
				</el-form-item>
				<el-form-item label="所属教学楼" prop="buliding_id">
					<el-select :popper-append-to-body="false" v-model="form.buliding_id" placeholder="请选择" @change="strValChange">
						<el-option v-for="i in floorData" :label="i.dimicile_buliding_name" :value="i.id" :key="i.id">
							{{ i.dimicile_buliding_name }}
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="楼层" prop="storey">
					<el-select :popper-append-to-body="false" v-model="form.storey" placeholder="请选择">
						<el-option v-for="i in floorList" :value="i" :label="i">{{ i }}</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="教室名称" prop="classroom_name">
					<el-input type="text" v-model="form.classroom_name" placeholder="请输入" style="width: 215rem" @input="classroomnameChange"></el-input><span style="margin-left: 24rem; font-size: 12rem; color: red" v-if="classroom_name_show">教室名称仅限15个字</span>
				</el-form-item>
				<el-form-item label="最大容纳人数" prop="max_number">
					<el-input v-model.number="form.max_number" onkeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode || event.which))) || event.which === 8" type="number" :maxlength="8" placeholder="请输入" style="width: 215rem"></el-input>
				</el-form-item>
			</el-form>
		</div>
		<!-- 底部按钮 -->


</edit-template>
</template>

<script>
import { building, classroomAdd } from '@/api/school'
import { mapGetters } from 'vuex'

export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  data() {
		return {
			checkData: [],
			schoolList: [],
			form: {
				school_id: null,
				buliding_id: '',
				storey: '',
				classroom_name: '',
				max_number: ''
			},
			floorData: [],
			floorList: [],
			classroom_name_show: false,
			rules: {
				school_id: [{ required: true, message: '请选择校区', trigger: 'change' }],
				buliding_id: [{ required: true, message: '请选择所属教学楼', trigger: 'change' }],
				storey: [{ required: true, message: '请选择楼层', trigger: 'change' }],
				classroom_name: [{ required: true, message: '请输入教室名称', trigger: 'blur' }],
				max_number: [{ required: true, message: '请输入最大容纳人数', trigger: 'blur' }]
			}
		}
	},
	computed: {
		...mapGetters(['userInfo'])
	},
	methods: {
		/**
		 * @description 更改校区
		 * @param val
		 */
		changeSchool(val) {
			this.form.storey = ''
			this.form.buliding_id = ''
			building(val).then(res => {
				this.floorData = res.data.data
			})
		},
		strValChange(value) {
			this.form.storey = ''
			this.floorData.forEach(i => {
				if (i.id == value) {
					let min = i.floor_min
					let max = i.floor_max
					this.floorList = []
					for (min; min <= max; min++) {
						if (min !== 0) {
							this.floorList.push(min)
						}
					}
				}
			})
		},
		save() {
			let { school_id, buliding_id, storey, classroom_name, max_number } = this.form
			let { checkInput } = this.$tools
			if (checkInput('empty', school_id)) {
				return this.$message.warning('请选择校区')
			} else if (checkInput('empty', buliding_id)) return this.$message.warning('请选择教学楼')
			else if (checkInput('empty', storey)) return this.$message.warning('请选择楼层')
			else if (checkInput('empty', classroom_name)) return this.$message.warning('请输入教室名')
			else if (checkInput('empty', max_number) || max_number < 0) return this.$message.warning('请输入最大容纳人数 , 且不允许小于0')
			else if (this.classroom_name_show) return this.$message.warning('教室名称仅限15个字')
			classroomAdd(this.form).then(res => {
				if (res.data.error.errorCode === 0) {
					this.$store.commit('setPage', 1)

					this.$router.back()
				}
			})
		},
		open() {
			this.$confirm('是否取消新增', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$router.back()
				})
				.catch(() => {})
		},
		init() {
			let { userinfo, school_arr } = this.userInfo
			this.$utils.commonApi.getSchoolList().then(res => {
				this.schoolList = res
			})
			if (userinfo.type !== '3') {
				let id = ''
				for (let i in school_arr) {
					id = i
				}
				this.schoolList = school_arr
			}
		},
		classroomnameChange(value) {
			this.classroom_name_show = value.length > 15;
		}
	},
	created() {
		this.init()
		// this.form.school_id =
	}
}
</script>

<style lang="scss" scoped>
.checkbox {
	min-width: 100rem;
	margin: 10rem;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
	-webkit-appearance: none !important;
}
::v-deep input[type='number'] {
	appearance: textfield !important;
	-moz-appearance: textfield !important;
}
</style>
